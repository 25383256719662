$(() => {
    const $menu_container = $('.site-header-menu .navbar').first();
    const $parents_with_children = $menu_container.find(
        '.primary-menu-container > .menu-wrapper > .menu-item-has-children'
    );
    $parents_with_children.each(function () {
        const $parent = $(this);
        const $main_anchor = $parent.find('> a');
        const $sub_menu = $parent.find('.sub-menu');
        const $mobile_toggle_button = $parent.find('.sub-menu-toggle');
        let hide_menu_timeout = null;

        $parent.addClass('closed');

        //if parent has a valid anchor tag, on large mobile devices expand the children before allow to be clicked.
        $main_anchor.on('click', function (event) {
            if (!is_mobile_width() && !$parent.hasClass('expanded')) {
                event.preventDefault();
            }
        });

        $mobile_toggle_button.on('click', function (event) {
            if ($parent.hasClass('expanded')) {
                fade_out();
            } else {
                fade_in();
            }
        });

        $parent
            .add($sub_menu)
            .on('mouseenter mouseover', () => !is_mobile_width() && fade_in())
            .on('mouseleave', () => !is_mobile_width() && fade_out());

        function fade_in() {
            clearTimeout(hide_menu_timeout);
            $sub_menu.off('transitionend webkitTransitionEnd oTransitionEnd');
            $parent.removeClass('closed');

            //delay to make sure we expand the children on mobile devices before allowing a parent item click.
            setTimeout(() => {
                $parent.addClass('expanded');
                $menu_container.addClass('has-menu-expanded');
            }, 100);
        }

        function fade_out() {
            clearTimeout(hide_menu_timeout);
            //300ms timeout to prevent accidental insta close on hover out
            hide_menu_timeout = setTimeout(() => {
                $sub_menu.one(
                    'transitionend webkitTransitionEnd oTransitionEnd',
                    hide_menu
                );
                $parent.removeClass('expanded');
            }, 300);
        }

        function hide_menu() {
            $parent.addClass('closed');
            if (!$menu_container.find('.expanded').length) {
                $menu_container.removeClass('has-menu-expanded');
            }
        }

        function is_mobile_width() {
            return $(window).outerWidth() <= 767;
        }
    });
});
